import React, { useEffect, useRef, useState } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { deleteCookie, getCookie, getCookies } from "cookies-next";
import { signIn, signOut, SignOutParams, useSession } from "next-auth/react";
import Aside from "components/Aside";
import { URI_LOGIN, URL_ADMIN_USERS_LIST } from "constants/urls";
import { appPageTitleSuffix, AuthProviders } from "constants/index";
import Header from "../../Header";
import { useThemeContext } from "../../../store";

const ImpersonatedUserBar = () => {
    const router = useRouter();
    const { data: session } = useSession();
    const isImpersonating = session?.isImpersonating;
    const userProfile = session?.userProfile;

    const handleDetachUser = async () => {
        const toDetach =
            session?.accessToken !== session?.originalToken;
        const signOutOptions: SignOutParams<any> = {};
        const backLink = "";
        if (!toDetach) {
            signOutOptions.callbackUrl = `${URI_LOGIN}${backLink}`;
        } else {
            signOutOptions.redirect = false;
        }
        await signOut(signOutOptions);
        if (toDetach) {
            try {
                await signIn(AuthProviders.LOGIN_AS_ADVISOR, {
                    originalToken: session?.originalToken
                }).then(() => {
                    const cookies = Object.keys(getCookies());
                    cookies.forEach(key => deleteCookie(key));
                });
            } catch (error) {
                console.log(
                    "Error handler was fired on the getting user token",
                    error
                );
            } finally {
                router.push(URL_ADMIN_USERS_LIST, undefined, {
                    shallow: true
                });
            }
        }
    };

    return !!isImpersonating ? (
        <div
            className="flex-shrink-0 flex px-4 lg:px-6 py-4 lg:pb-3 lg:pt-[11px] bg-link text-white pointer-events-none lg:pointer-events-auto impersonate-wrapper"
            data-testid="impersonate-wrapper"
        >
            <div
                className="flex items-center text-medium font-bold mr-2 lg:mx-auto"
                data-testid="impersonate-content"
            >
                You are impersonating {userProfile?.firstName}{" "}
                {userProfile?.lastName} (
                {userProfile?.email as string})
            </div>
            <button
                className="inline-flex ml-auto lg:ml-0 items-center justify-center px-4 py-3 font-bold bg-white text-main text-small rounded-3xl border border-stroke focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-600"
                onClick={handleDetachUser}
                data-testid="impersonate-detach"
            >
                Detach
            </button>
        </div>
    ) : null;
};

export default function Layout({
    children,
    title
}: {
    children?: React.ReactNode;
    title?: string;
}) {
    const { mainContainerRef } = useThemeContext();

    const [navbarOpen, setNavbarOpen] = useState(false);

    return (
        <>
            <Head>
                <title>{title ? `${title} - ${appPageTitleSuffix}` : ""}</title>
            </Head>
            {/* Y-CONTAINER: Children (other than X-CONTAINER) should be full viewport width and have fixed height. They will layout in order top to bottom. All must have class flex-grow-0 and flex-shrink-0 classes applied. */}
            <div className="h-dvh flex flex-col">
                {/* FULL-WIDTH HEADERS */}
                <ImpersonatedUserBar />
                <Header showNavbar={() => setNavbarOpen(true)} layoutV2 />
                {/* X-CONTAINER: Container will grow vertically to leverage all space not used by headers and footers. Children should have height that leverages all vertically available space (use h-full to do so) and have fixed width. They will layout left to right. */}
                <div className="flex flex-grow overflow-hidden">
                    {/* LEFT SIDEBAR(S) */}
                    <Aside
                        isOpen={navbarOpen}
                        hideNavbar={() => setNavbarOpen(false)}
                        layoutV2
                    />
                    {/* MAIN CONTENT: Container will grow horizontally to leverage all space not used by left and right sidebars. */}
                    <div
                        className="flex flex-grow overflow-auto"
                        ref={mainContainerRef}
                    >
                        {children}
                    </div>
                    {/* RIGHT SIDEBAR(S) [currently none] */}
                </div>
                {/* FULL-WIDTH FOOTERS  [currently none] */}
            </div>
        </>
    );
}
