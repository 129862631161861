import Layout, { LayoutProps } from "components/common/v1/Layout";
import SystemBanners from "components/SystemBanners";
import { PageTitle } from "../v2/HeaderBannerLayout";

export interface HeaderBannerLayoutProps extends LayoutProps {
    headerText?: string; // If header is not passed in, we'll use the title. (This is the most common use case.)
}

export default function HeaderBannerLayout({
    headerText,
    ...props
}: HeaderBannerLayoutProps) {
    const { children, title } = props;
    return (
        <Layout {...props}>
            <div className="container-1440">
                <SystemBanners />
                <PageTitle className="mb-4 md:mb-6">
                    {headerText ?? title}
                </PageTitle>
                {children}
            </div>
        </Layout>
    );
}
