import Layout from "components/common/v2/Layout";
import SystemBanners from "components/SystemBanners";
import { HeaderBannerLayoutProps } from "../v1/HeaderBannerLayout";
import React, { HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";

/** The title text for a portal page */
export const PageTitle = (
    props: HTMLAttributes<HTMLHeadingElement>
): JSX.Element => (
    <h1
        {...props}
        className={classNames(
            "text-displayLargeMobile lg:text-displayLarge font-semibold text-main",
            props.className
        )}
    />
);

export default function HeaderBannerLayout({
    children,
    title,
    headerText,
    rightOfHeaderContent,
    noPadding = false,
    breadcrumbs
}: HeaderBannerLayoutProps & {
    rightOfHeaderContent?: ReactNode;
    breadcrumbs?: ReactNode;
}) {
    const displayTitle = headerText ?? title;
    return (
        <Layout title={title}>
            <div
                className={`w-full flex flex-col flex-grow ${
                    noPadding ? "" : "p-6 md:py-12 md:px-16"
                }`}
            >
                <div className="w-full container-1440 flex flex-col flex-grow">
                    <SystemBanners />
                    {breadcrumbs && <div className="mb-4">{breadcrumbs}</div>}
                    {(displayTitle || rightOfHeaderContent) && (
                        <div className="w-full flex-shrink-0 flex items-end justify-between mb-4 md:mb-6">
                            {displayTitle && (
                                <PageTitle>{displayTitle}</PageTitle>
                            )}
                            {rightOfHeaderContent}
                        </div>
                    )}
                    {children}
                </div>
            </div>
        </Layout>
    );
}
